import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NetherlandsFlag } from '../assets/images/nl.svg';
import { ReactComponent as UnitedKingdomFlag } from '../assets/images/gb.svg';

const LanguageChange = () => {
  const { t, i18n } = useTranslation();
  const DEFAULT_LANGUAGE = 'en';
  const [currLang, setCurrLang] = useState(DEFAULT_LANGUAGE);

  const handleSwitchLanguage = (lang: any) => {
    console.log('switch language: lang', lang);
    i18n.changeLanguage(lang).then((res: any) => {
      setCurrLang(lang);
      localStorage.setItem('lang', lang);
    });
  };

  useEffect(() => {
    const loadedLang = localStorage.getItem('lang');
    console.log('loadedLang', loadedLang);

    if (loadedLang) {
      i18n.changeLanguage(loadedLang).then((res: any) => {
        setCurrLang(loadedLang);
      });
    }
  }, [i18n]);
  return (
    <div className="lang-change">
      <NetherlandsFlag
        className={currLang === 'nl' ? 'active-lang' : ''}
        onClick={(e) => handleSwitchLanguage('nl')}
      />
      <UnitedKingdomFlag
        className={currLang === 'en' ? 'active-lang' : ''}
        onClick={(e) => handleSwitchLanguage('en')}
      />
    </div>
  );
};

export default LanguageChange;
