import { toast } from 'react-toastify';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo, InputField, Button } from '../../Components';
import { API_BASE_URL } from '../../services/urls';
import { API } from '../../api';
import LanguageChange from '../../Components/LanguageChange';
import './style.css';

const ForgotPassword: FC = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t('titles.forgot_password');
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const formDataNew = new FormData();
    formDataNew.append('email', email);
    await API.restaurantApi
      .forgetPassword(formDataNew)
      .then((response) => {
        toast.success(t('alerts.success.recovery_email_send'), {
          containerId: 'B',
        });
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 3000);
      })
      .catch((error) => {
        toast.error(t('alerts.error.wrong_email'), { containerId: 'B' });
      });
  };

  return (
    <div className="container mt-5">
      <div className="forgot-pass-lang">
        <LanguageChange />
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
            <Logo infoText={t('reset_password.msg')} mutedText={true} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField name="email" placeholder="restaurant@web.com" />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-2">
            <Button name={t('reset_password.send')} dangerButton={true} btnLongWidth={false} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
