import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../services/urls';
import { EditArticleModel, Header, Sidebar } from '../../Components';
import { API } from '../../api';
import { getArticles } from '../../store/actions';

let ps: any;

const EditArticle: FC = () => {
  const [modal, setModal] = useState(false);
  const [articleId, setArticleId] = useState(0);
  const [Articleobj, setArticleobj] = useState(0);

  const articleOptionsName = useSelector(({ articles }: StoreRoot) => articles);

  const dispatch = useDispatch();

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t('titles.articles');
    // @ts-ignore
    document.getElementById('editarticle_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  useEffect(() => {
    dispatch(getArticles(id));
  }, [id]);

  const toggle = (id: any, articleopt: any) => {
    setModal(!modal);
    setArticleId(id);
    setArticleobj(articleopt);
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  };

  const articledelete = async (id: string) => {
    const rest_id = localStorage.getItem('rest_id') as string;
    await API.restaurantApi
      .deleteArticles(rest_id, id)
      .then((res) => {
        toast.success(t('alerts.success.delete'), { containerId: 'B' });
        dispatch(getArticles(rest_id));
      })
      .catch((err) => {
        dispatch(getArticles(rest_id));
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {articleOptionsName && (
          <div className="row mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <div className="table-responsive">
                <span className="h4 mb-3">{t('articles.heading')}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => toggle('new', 'new')}
                  className="btn btn-danger rounded-0 shadow-none"
                >
                  {t('articles.add_button')}
                </button>
                <table className="table table-striped table-bordered mt-3">
                  <thead>
                    <tr>
                      <th className="small">{t('articles.name')}</th>
                      <th className="small">{t('articles.price')}</th>
                      <th className="small">{t('articles.category')}</th>
                      <th className="small">{t('articles.vat_pickup')}</th>
                      <th className="small">{t('articles.vat_delivery')}</th>
                      <th className="small">{t('articles.more_info')}</th>
                      <th className="small">{t('articles.ingredients')}</th>
                      <th className="small">{t('articles.image')}</th>
                      <th className="small">{t('articles.action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articleOptionsName.map((articleopt: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td className="small">{articleopt.articlename}</td>
                            <td className="small">{articleopt.price}</td>
                            <td className="small">
                              {articleopt.category ? articleopt.category.name : ''}
                            </td>
                            <td className="small">{articleopt.pickuptax}</td>
                            <td className="small">{articleopt.dineintax}</td>
                            <td className="small">{articleopt.description}</td>
                            <td>
                              {articleopt.ingredient
                                ? articleopt.ingredient.map((ingred: any, index: number) => (
                                    <div key={index}>{`${ingred.name},`}</div>
                                  ))
                                : ''}
                            </td>
                            <td>
                              {articleopt.image && (
                                <img
                                  className="restaurant_images"
                                  id="blah"
                                  src={API_BASE_URL + articleopt.image}
                                  alt=""
                                />
                              )}
                            </td>
                            <td className="small">
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                onClick={() => toggle(articleopt.id, articleopt)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                onClick={() => articledelete(articleopt.id)}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <EditArticleModel
        article={Articleobj}
        modal={modal}
        setModal={setModal}
        toggle={closeModal}
        articleId={articleId}
      />
    </>
  );
};

export default EditArticle;
