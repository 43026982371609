import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { getRestaurantData } from '../../store/actions';
import { API } from '../../api';
import { API_BASE_URL, MAIN_APP_URL } from '../../services/urls';
import {
  Button,
  DownloadQR,
  Header,
  InputField,
  Sidebar,
  WorkingHoursModal,
} from '../../Components';
import './style.css';
import { useTranslation } from 'react-i18next';

let ps: any;

const Dashboard: FC = () => {
  const [modal, setModal] = useState(false);
  const [qrmodal, setQrModal] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);
  const [workinghoursData, setworkinghoursData] = useState([]);
  const [workinghourId, setworkinghourId] = useState(0);
  const [workinghourobj, setworkinghourobj] = useState<any>(0);
  const { id } = useParams<any>();
  const restid = localStorage.getItem('rest_id') as string;
  const mainPanel = useRef<any>();

  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const getWorkingHours = () => {
    API.restaurantApi
      .getRestaurantWorkingHours(restid)
      .then((res) => {
        setworkinghoursData(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    setRestaurantData(restaurant);
  }, [restaurant]);

  useEffect(() => {
    document.title = t('titles.dashboard');
    // @ts-ignore
    document.getElementById('dashboard_colorchange').style.color = 'darkred';
    dispatch(getRestaurantData(restid));
    getWorkingHours();

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [qrmodal, modal, id, restid]);

  const toggle = (id: any, workinghour: any) => {
    setModal(!modal);
    setworkinghourId(id);
    setworkinghourobj(workinghour);
    getWorkingHours();
  };

  const deleteWorkinghour = async (id: any) => {
    await API.restaurantApi.deleteRestaurantWorkingHours(id).then((res) => {
      toast.success(t('alerts.success.delete'), { containerId: 'B' });
      getWorkingHours();
    });
  };

  const changerestaurantdata = (e: any) => {
    setRestaurantData({
      ...restaurantData,
      [e.target.name]: [e.target.value],
    });
  };

  const handleChangeStatus = () => {
    let valueA;
    if (restaurantData.Availability === 'OFFLINE') {
      valueA = 'ONLINE';
    } else {
      valueA = 'OFFLINE';
    }
    setRestaurantData({
      ...restaurantData,
      Availability: valueA,
    });
  };

  const handleChangeDelivery = (e: any) => {
    let valueA;
    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      delivery_option: e,
    });
  };

  const handleChangePickup = (e: any) => {
    let valueA;

    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      pickup_option: e,
    });
  };

  const SubmitRestaurantdata = async (e: any) => {
    e.preventDefault();
    const restname = e.target.elements.RestaurantName.value;
    const location = e.target.elements.Location.value;
    const zipcode = e.target.elements.zipcode.value;
    // const restaurantImage = e.target.elements.restaurantImage.value;
    const logoimage = e.target.elements.Logo.files[0];
    const tablesCount = e.target.elements.NumberOfTables.value;
    let availibilty;
    const pickup_option = true as any;
    const delivery_option = true as any;
    const paynowbutton = e.target.elements.paynowbutton.value;
    const paylaterbutton = e.target.elements.paylaterbutton.value;
    const askforbillbutton = e.target.elements.askforbillbutton.value;
    const askforservicebutton = e.target.elements.askforservicebutton.value;
    const apikey = e.target.elements.apikey.value;
    const restaurantImage = e.target.elements.image.files[0];
    const slug = e.target.elements.slug.value;
    const enable_sms = e.target.elements.enable_sms.value;
    const max_order = e.target.elements.max_order.value;
    const time_interval = e.target.elements.time_interval.value;
    const delivery_cost = e.target.elements.delivery_cost.value as any;
    const min_amount_for_free_delivery = e.target.elements.min_amount_for_free_delivery.value;
    let availablity_zone_zipcodes;
    if (
      e.target.elements.availablity_zone_zipcodes.value.replaceAll(/,/g, ' ').split(' ').pop() ===
      ''
    ) {
      availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value
        .replaceAll(/,/g, ' ')
        .split(' ');
    }
    const service_cost_fixed_amount = e.target.elements.service_cost_fixed_amount.value;
    const service_cost_percentage = e.target.elements.service_cost_percentage.value;
    const service_cost_type = e.target.elements.service_cost_type.value;
    const title_ = e.target.elements.page_title.value;
    const defaultMinutes_ = e.target.elements.defaultMinutes.value;
    const customer_msg_ = e.target.elements.customer_msg.value;
    let meta_titles = [];
    if (e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_titles = e.target.elements.meta_titles.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_titles = e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ');
    }
    let meta_descriptions = [];
    if (e.target.elements.meta_descriptions.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_descriptions = e.target.elements.meta_descriptions.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_descriptions = e.target.elements.meta_descriptions.value
        .replaceAll(/,/g, ' ')
        .split(' ');
    }
    let meta_keywords = [];
    if (e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_keywords = e.target.elements.meta_keywords.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_keywords = e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ');
    }

    const email_restaurant = e.target.elements.email_restaurant.value;
    const phone_restaurant = e.target.elements.phone_restaurant.value;

    const formData = new FormData();

    formData.append('RestaurantName', restname);

    if (e.target.elements.Availability.checked === true) {
      availibilty = 'ONLINE';
    } else {
      availibilty = 'OFFLINE';
    }

    if (restaurantImage !== undefined) {
      formData.append('image', restaurantImage);
    }
    formData.append('Location', location);
    if (logoimage !== undefined) {
      formData.append('Logo', logoimage);
    }

    formData.append('askforbillbutton', askforbillbutton);
    formData.append('askforservicebutton', askforservicebutton);
    formData.append('enable_sms', enable_sms);
    formData.append('paynowbutton', paynowbutton);
    formData.append('paylaterbutton', paylaterbutton);

    formData.append('NumberOfTables', tablesCount);
    formData.append('Availability', availibilty);
    formData.append('pickup_option', pickup_option);
    formData.append('delivery_option', delivery_option);

    formData.append('apikey', apikey);
    formData.append('slug', slug);
    formData.append('max_order', max_order);
    formData.append('time_interval', time_interval);
    formData.append('zipcode', zipcode);

    formData.append('delivery_cost', parseFloat(String(+delivery_cost)).toFixed(2));
    formData.append(
      'min_amount_for_free_delivery',
      parseFloat(String(+min_amount_for_free_delivery)).toFixed(2)
    );
    formData.append('availablity_zone_zipcodes', JSON.stringify(availablity_zone_zipcodes));
    formData.append('meta_titles', JSON.stringify(meta_titles));
    formData.append('meta_keywords', JSON.stringify(meta_keywords));

    formData.append('meta_descriptions', JSON.stringify(meta_descriptions));

    formData.append('service_cost_fixed_amount', service_cost_fixed_amount);
    formData.append('service_cost_percentage', service_cost_percentage);
    formData.append('service_cost_type', service_cost_type);
    formData.append('page_title', title_);

    formData.append('defaultMinutes', defaultMinutes_);
    formData.append('customer_msg', customer_msg_);

    formData.append('email_restaurant', email_restaurant);
    formData.append('phone_restaurant', phone_restaurant);

    await API.restaurantApi
      .updateRestaurantData(formData, restid)
      .then(() => {
        toast.success(t('alerts.success.update'), { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mr-md-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            <span className="h4 mb-3">{t('dashboard.heading')}</span>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 mt-3 offsetMd5">
            <form onSubmit={(e) => SubmitRestaurantdata(e)}>
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {t('dashboard.name')}
              </label>
              <InputField
                name="RestaurantName"
                placeholder={t('dashboard.name')}
                value={restaurantData.RestaurantName || ''}
                onChange={(e: any) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {t('dashboard.location')}
              </label>
              <textarea
                className="form-control mt-1"
                rows={6}
                name="Location"
                placeholder={t('dashboard.location')}
                value={restaurantData.Location}
                onChange={(e) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {t('dashboard.zone')}
              </label>
              <textarea
                className="form-control mt-1"
                rows={8}
                name="availablity_zone_zipcodes"
                placeholder={t('dashboard.postcodes')}
                value={restaurantData.availablity_zone_zipcodes}
                onChange={(e) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {t('dashboard.postcode')}
              </label>
              <input
                className="form-control mt-1"
                name="zipcode"
                placeholder={t('dashboard.postcode')}
                value={restaurantData.zipcode || ''}
                onChange={(e) => changerestaurantdata(e)}
              />
              <h6 className="mt-3">{t('dashboard.banner')}</h6>
              <div>
                <input id="restimage" name="image" type="file" className="form-control mt-3 p-1" />
                <h6>{t('dashboard.image')}</h6>
                {restaurantData.image && (
                  <img
                    className="restaurant_images"
                    id="blah"
                    src={API_BASE_URL + restaurantData.image}
                    onChange={(e) => changerestaurantdata(e)}
                    alt=""
                  />
                )}
              </div>
              <h6 className="mt-3">{t('dashboard.logo')}</h6>
              <div>
                <input
                  id="logo"
                  name="Logo"
                  type="file"
                  className="form-control mt-3 p-1"
                  onChange={(e) => changerestaurantdata(e)}
                />
                <h6>{t('dashboard.image')}</h6>
                {restaurantData.Logo && (
                  <img
                    id="blah"
                    className="restaurant_images"
                    src={API_BASE_URL + restaurantData.Logo}
                    alt=""
                  />
                )}
              </div>
              <div className="form-group">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.qr_tables')}
                </label>
                <input
                  name="NumberOfTables"
                  className="form-control"
                  type="number"
                  placeholder={t('dashboard.enter_number')}
                  min="0"
                  value={restaurantData.NumberOfTables || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="availibility"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {t('dashboard.status')}
                </label>
                <div className="form-group">
                  <Switch
                    onChange={() => handleChangeStatus()}
                    onColor="#dc3545"
                    name="Availability"
                    checked={restaurantData.Availability === 'ONLINE'}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="defaultMinutes"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {t('dashboard.preparation_time')}
                </label>
                <div className="form-group">
                  <input
                    name="defaultMinutes"
                    className="form-control"
                    type="number"
                    placeholder={t('dashboard.enter_number')}
                    min="0"
                    value={restaurantData.defaultMinutes || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="customer_msg"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {t('dashboard.closed_msg')}
                </label>
                <div className="form-group">
                  <input
                    name="customer_msg"
                    className="form-control"
                    type="input"
                    id="customer_msg"
                    placeholder={t('dashboard.enter_msg')}
                    value={restaurantData.customer_msg || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>
              </div>
              <div className="form-group">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.mollie_api')}
                </label>
                <input
                  name="apikey"
                  className="form-control"
                  type="input"
                  id="api_id"
                  placeholder={t('dashboard.enter_api')}
                  value={restaurantData.apikey || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {t('dashboard.online_payments')}
                  </label>
                  <select
                    value={restaurantData.paynowbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="paynowbutton"
                    className="form-control"
                    id="paynow"
                  >
                    <option value="true">{t('dashboard.on')}</option>
                    <option value="false">{t('dashboard.off')}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {t('dashboard.pay_at_delivery')}
                  </label>
                  <select
                    value={restaurantData.paylaterbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="paylaterbutton"
                    className="form-control"
                    id="paynow"
                  >
                    <option value="true">{t('dashboard.on')}</option>
                    <option value="false">{t('dashboard.off')}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {t('dashboard.service_request')}
                  </label>
                  <select
                    value={restaurantData.askforservicebutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="askforservicebutton"
                    className="form-control"
                    id="askforservicebutton"
                  >
                    <option value="true">{t('dashboard.on')}</option>
                    <option value="false">{t('dashboard.off')}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {t('dashboard.receipt_request')}
                  </label>
                  <select
                    value={restaurantData.askforbillbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="askforbillbutton"
                    className="form-control"
                    id="askforbillbutton"
                  >
                    <option value="true">{t('dashboard.on')}</option>
                    <option value="false">{t('dashboard.off')}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="enable_sms"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    {t('dashboard.activate_sms')}
                  </label>
                  <select
                    value={restaurantData.enable_sms}
                    onChange={(e) => changerestaurantdata(e)}
                    name="enable_sms"
                    className="form-control"
                    id="enable_sms"
                  >
                    <option value="true">{t('dashboard.on')}</option>
                    <option value="false">{t('dashboard.off')}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="service_cost_type"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    {t('dashboard.service_cost_type')}
                  </label>
                  <select
                    value={restaurantData.service_cost_type}
                    onChange={(e) => changerestaurantdata(e)}
                    name="service_cost_type"
                    className="form-control"
                    id="service_cost_type"
                  >
                    <option value="fixed_amount">{t('dashboard.fixed_option')}</option>
                    <option value="percentage">{t('dashboard.percentage_option')}</option>
                  </select>
                </div>
                <label
                  htmlFor="service_cost_fixed_amount"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {t('dashboard.fixed_amount')}
                </label>
                <InputField
                  disabled={
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'percentage') ||
                    restaurantData.service_cost_type === 'percentage'
                  }
                  name="service_cost_fixed_amount"
                  placeholder={t('dashboard.price_placeholder')}
                  value={restaurantData.service_cost_fixed_amount || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="service_cost_percentage"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {t('dashboard.percentage')}
                </label>
                <InputField
                  disabled={
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'fixed_amount') ||
                    restaurantData.service_cost_type === 'fixed_amount'
                  }
                  name="service_cost_percentage"
                  placeholder={t('dashboard.percentage_placeholder')}
                  value={restaurantData.service_cost_percentage || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="delveryCost"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {t('dashboard.delivery_cost')}
                </label>
                <InputField
                  name="delivery_cost"
                  placeholder={t('dashboard.price_placeholder')}
                  value={restaurantData.delivery_cost || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="freeDelivery"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {t('dashboard.free_delivery_amount')}
                </label>
                <InputField
                  name="min_amount_for_free_delivery"
                  placeholder={t('dashboard.price_placeholder')}
                  value={restaurantData.min_amount_for_free_delivery || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.page_title')}
                </label>
                <InputField
                  name="page_title"
                  placeholder={t('dashboard.page_title')}
                  value={restaurantData.page_title || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.meta_titles')}
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_titles"
                  placeholder={t('dashboard.meta_titles')}
                  value={restaurantData.meta_titles}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.meta_desc')}
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_descriptions"
                  placeholder={t('dashboard.meta_desc')}
                  value={restaurantData.meta_descriptions}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.meta_keywords')}
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_keywords"
                  placeholder={t('dashboard.meta_keywords')}
                  value={restaurantData.meta_keywords}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.slug')}
                </label>
                <InputField
                  required
                  name="slug"
                  placeholder={t('dashboard.slug')}
                  value={restaurantData.slug || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.max_orders')}
                </label>
                <InputField
                  name="max_order"
                  placeholder={t('dashboard.max_orders')}
                  value={restaurantData.max_order || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.time_interval')}
                </label>
                <InputField
                  name="time_interval"
                  placeholder={t('dashboard.time_interval')}
                  value={restaurantData.time_interval || 'z'}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.email')}
                </label>
                <InputField
                  name="email_restaurant"
                  type="email"
                  placeholder={t('dashboard.email')}
                  value={restaurantData.email_restaurant || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.phone')}
                </label>
                <InputField
                  name="phone_restaurant"
                  type="tel"
                  pattern="[0-9]+"
                  placeholder={t('dashboard.phone')}
                  value={restaurantData.phone_restaurant || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.url_label')}
                </label>{' '}
                <a href={`${MAIN_APP_URL}/restaurant/${restaurantData.slug}`}>
                  {t('dashboard.visit_url')}
                </a>
              </div>
              <div className="text-right">
                <Button
                  name={t('dashboard.save')}
                  htmlType="submit"
                  dangerButton={true}
                  btnLongWidth={false}
                />
              </div>
            </form>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5">
            <div className="p-3">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-success rounded-0 shadow-none"
              >
                {t('dashboard.add_hours')}
              </button>
              <div className="row border border-secondary mt-2">
                <div className="col-sm-12 text-center mt-2">
                  <h4>{t('dashboard.hours')}</h4>
                </div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {t('dashboard.day')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {t('dashboard.start')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {t('dashboard.end')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {t('dashboard.delivery_pickup')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {t('dashboard.action')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {workinghoursData &&
                      workinghoursData.map((workinghour: any, index) => {
                        return (
                          <tr key={index}>
                            <td>{t(`weekdays.${workinghour.weekday}`)}</td>
                            <td>{workinghour.from_hour}</td>
                            <td>{workinghour.to_hour}</td>
                            <td>
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}>
                                  {' '}
                                  {t('dashboard.delivery')}:{' '}
                                  <b>
                                    {workinghour.delivery_option
                                      ? t('dashboard.on')
                                      : t('dashboard.off')}
                                  </b>
                                </div>
                                <div style={{ width: '50%' }}>
                                  {' '}
                                  {t('dashboard.pickup')}:{' '}
                                  <b>
                                    {workinghour.pickup_option
                                      ? t('dashboard.on')
                                      : t('dashboard.off')}
                                  </b>
                                </div>
                              </div>
                            </td>

                            <td>
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                id={workinghour.id}
                                onClick={() => toggle(workinghour.id, workinghour)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                id={workinghour.id}
                                onClick={() => deleteWorkinghour(workinghour.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WorkingHoursModal
        workinghourobj={workinghourobj}
        modal={modal}
        toggle={toggle}
        workinghourid={workinghourId}
      />
      <DownloadQR />
    </>
  );
};
export default Dashboard;
