import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { API } from '../../api';
import { Logo, InputField, Button } from '../../Components';
import LanguageChange from '../../Components/LanguageChange';
import './style.css';

const Login: FC = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t('titles.login');
  }, []);

  const handleFormSubmission = async (event: any) => {
    event.preventDefault();
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;
    await API.restaurantApi
      .login({ username, password })
      .then((response) => {
        console.log(response.data.data.token);
        window.localStorage.setItem('jwt', response.data.data.token);
        window.localStorage.setItem('rest_id', response.data.data.restaurant.id);
        if (response.data.data) {
          // @ts-ignore
          window.location = `/admin/dashboard/${response.data.data.restaurant.id}`;
        }
      })
      .catch((error) => {
        toast.error(t('alerts.error.email_or_password'), { containerId: 'B' });
      });
  };

  return (
    <form className="login-form" onSubmit={(event) => handleFormSubmission(event)}>
      <div className="login-lang">
        <LanguageChange />
      </div>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
            <Logo infoText={t('login.login')} mutedText={false} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="email" name="username" placeholder={t('login.email')} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="password" name="password" placeholder={t('login.password')} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mb-2 text-right">
            <Link to="/auth/forgotPassword">
              <span className="text-muted small">{t('login.forgot_password')}</span>
            </Link>
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-2">
            <Button
              htmlType="submit"
              name={t('login.login')}
              dangerButton={true}
              btnLongWidth={true}
              Link={true}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default Login;
