import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, OrderModel, Sidebar } from '../../Components';
import PerfectScrollbar from 'perfect-scrollbar';
import { API } from '../../api';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader';
import PrinterModal from '../../Components/PrinterModal';
import { API_BASE_URL } from '../../services/urls';

const { getPrintersList, getPrinterUrl, partialUpdatePrinter, removePrinter } = API.restaurantApi;

let ps: any;

const Printers = () => {
  const [printersList, setPrintersList] = useState<any[]>([]);
  const [modal, setModal] = useState(false);
  const [currId, setCurrId] = useState(null);
  const [currPrinter, setCurrPrinter] = useState(null);
  const [activStatuses, setActiveStatuses] = useState<any[]>([]);
  const [printer_url, setPrinterUrl] = useState<string>('');
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const { t, i18n } = useTranslation();

  const getRestPrintersList = async (restid: string) => {
    await getPrintersList(restid)
      .then((res: any) => {
        setPrintersList(res);
        // @ts-ignore
        let newActivStatuses = [];
        res.map((item: any) => {
          newActivStatuses.push({
            id: item.id,
            activity_status: item.activity_status === 'online',
          });
        });
        // @ts-ignore
        setActiveStatuses(newActivStatuses);
      })
      .catch((err: any) => {
        // Add Toast
        console.log('err', err);
      });
  };

  const getUrlForPrinter = async (restid: string) => {
    await getPrinterUrl(restid)
      .then((res: any) => {
        setPrinterUrl(res.printer_url);
      })
      .catch((err: any) => console.log('Error', err));
  };

  useEffect(() => {
    getRestPrintersList(id);
    getUrlForPrinter(id);
  }, []);

  useEffect(() => {
    document.title = t('titles.printers');
    // @ts-ignore
    document.getElementById('printers_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  const toggle = (id: any, printer: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setModal(!modal);
    setCurrId(id);
    setCurrPrinter(printer);
  };

  const deleteSelectedPrinter = async (id: any) => {
    const cont_id = 'RemovePrinter';
    await removePrinter(id)
      .then((res: any) => {
        toast.success(t('alerts.success.printer_delete'), { containerId: cont_id });
      })
      .catch((err: any) => {
        toast.error(t('alerts.error.printer_delete'), { containerId: cont_id });
      });
  };

  const deleteprinter = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string;
    deleteSelectedPrinter(id);
  };

  const getActivityStatus = (id: any) => {
    if (activStatuses && activStatuses.length > 0) {
      return activStatuses.find((item: any) => item.id === id).activity_status;
    }
    return false;
  };

  const partialUpdateSelectedPrinter = async (printer_id: any, data: any) => {
    const cont_id = 'UpdatePrinter';
    await partialUpdatePrinter(printer_id, data)
      .then((res) => {
        toast.success(t('alerts.success.printer_update'), { containerId: cont_id });
        getRestPrintersList(id);
      })
      .catch((err) => {
        toast.error(t('alerts.error.printer_update'), { containerId: cont_id });
        getRestPrintersList(id);
      });
  };

  const handleActivityStatus = (id: any) => {
    const val = getActivityStatus(id);
    let newActivStatuses = activStatuses.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          activity_status: !val,
        };
      }
      return item;
    });
    setActiveStatuses(newActivStatuses);
    const formData = new FormData();
    formData.append('activity_status', val ? 'offline' : 'online');
    partialUpdateSelectedPrinter(id, formData);
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <span className="h4 mb-3">{t('printers.heading')}</span>
            <br />
            <span className="h5 mb-3">{t('printers.your_url')}</span>{' '}
            <a
              className="h5 mb-3"
              target="_blank"
              href="https://www.star-m.jp/products/s_print/CloudPRNTSDK/Documentation/en/developerguide/clientconfiguration.html"
            >
              <i className="fa fa-info-circle text-success"></i>
            </a>
            <br />
            <span className="h6 mb-3" title="Server URL CloudPRNT setting for your printer">
              {API_BASE_URL}
              {printer_url}
            </span>
            <div className="table-responsive mt-4">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="small">{t('printers.id')}</th>
                    <th className="small">{t('printers.unique_id')}</th>
                    <th className="small">{t('printers.action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {printersList &&
                    printersList.length > 0 &&
                    printersList?.map((printer: any) => {
                      return (
                        <tr key={printer.id}>
                          <td>{printer.id}</td>
                          <td>{printer.unique_id}</td>
                          <td>
                            <button
                              className={`btn ${
                                getActivityStatus(printer.id) ? 'btn-success' : 'btn-danger'
                              } btn-sm mr-3`}
                              onClick={() => handleActivityStatus(printer.id)}
                            >
                              {getActivityStatus(printer.id) ? t('printers.on') : t('printers.off')}
                            </button>
                            <i
                              className="fa fa-info-circle cursr"
                              aria-hidden="true"
                              id={printer.id}
                              onClick={() => toggle(printer.id, printer)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              onClick={() => deleteprinter(printer.id)}
                              id={printer.id}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PrinterModal printer={currPrinter} modal={modal} toggle={toggle} printerId={currId} />
    </>
  );
};

export default Printers;
