import { toast } from 'react-toastify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Logo, InputField, Button } from '../../Components';
import { API } from '../../api';
import LanguageChange from '../../Components/LanguageChange';
import './style.css';

const ForgotPasswordReset = () => {
  const [SuccessResponce, setSuccessResponce] = useState(2);
  const { restid } = useParams<any>();
  const { code } = useParams<any>();

  const { t, i18n } = useTranslation();

  const confirmPassword = async () => {
    const formData = new FormData();
    formData.append('id', restid);
    formData.append('verification_key', code);
    await API.restaurantApi
      .confirmPassword(formData, restid, code)
      .then((res) => {
        setSuccessResponce(1);
      })
      .catch((err) => {
        setSuccessResponce(0);
      });
  };

  useEffect(() => {
    document.title = t('titles.reset_password');
    confirmPassword();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newpassword = e.target.elements.newpassword.value;
    const confirmpassword = e.target.elements.confirmpassword.value;
    if (confirmpassword === newpassword) {
      const formDataNew = new FormData();

      formDataNew.append('id', restid);
      formDataNew.append('password', newpassword);
      formDataNew.append('confirmpassword', confirmpassword);
      formDataNew.append('verification_key', code);
      await API.restaurantApi
        .resetPassword(formDataNew, restid, code)
        .then((response) => {
          toast.success(t('alerts.success.password_change'), { containerId: 'B' });
          setTimeout(() => {
            window.location.href = '/auth/login';
          }, 3000);
        })
        .catch((error) => {
          toast.error(t('alerts.error.some_error'), { containerId: 'B' });
        });
    } else {
      toast.error(t('alerts.error.passwords_differ'), { containerId: 'B' });
    }
  };

  return (
    <div className="container mt-5">
      <div className="forgot-pass-lang">
        <LanguageChange />
      </div>
      {SuccessResponce > 0 && (
        <div className="container mt-5">
          <div className="alert alert-success" role="alert">
            {t('reset_password.success_msg')}
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row align-items-center">
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                <Logo infoText={t('reset_password.recover_password')} mutedText={false} />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                <InputField
                  type="password"
                  name="newpassword"
                  minlength="8"
                  placeholder={t('reset_password.new')}
                />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                <InputField
                  type="password"
                  name="confirmpassword"
                  minlength="8"
                  placeholder={t('reset_password.confirm_pass')}
                />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-3">
                <Button name={t('reset_password.save')} dangerButton={true} btnLongWidth={true} />
              </div>
            </div>
          </form>
        </div>
      )}
      {SuccessResponce === 0 && (
        <>
          <div className="container mt-5">
            <div className="alert alert-danger" role="alert">
              {t('reset_password.failure_msg')}
            </div>
            <Button
              name={t('reset_password.go_back')}
              dangerButton={true}
              btnLongWidth={false}
              isLink={true}
              link="/auth/forgotPassword"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPasswordReset;
