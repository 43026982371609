import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, InputField } from './index';
import { API } from '../api';

const WorkingHoursModal: FC<any> = (props) => {
  const [Workinghour, setWorkinghour] = useState<any>('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (typeof props.workinghourobj === 'undefined') {
      setWorkinghour('');
    } else {
      setWorkinghour(props.workinghourobj);
    }
  }, [props]);

  const refresh = () => {
    window.location.reload();
  };

  const changeworkinghour = (e: any) => {
    setWorkinghour(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const weekday = e.target.elements.weekday.value;
    const to_hour = e.target.elements.to_hour.value;
    const from_hour = e.target.elements.from_hour.value;
    const id = e.target.elements.id.value;
    const pickoption = e.target.elements.pickupoption.value;
    const deliveryoption = e.target.elements.deliveryoption.value;

    if (pickoption === 'false' && deliveryoption === 'false') {
      toast.error(t('alerts.error.delivery_method'), { containerId: 'B' });

      return 0;
    }

    const restid = localStorage.getItem('rest_id');

    if (id === '') {
      await API.restaurantApi
        .createRestaurantWorkingHours(
          {
            restaurant: restid as string,
            weekday,
            to_hour,
            from_hour,
            pickup_option: pickoption,
            delivery_option: deliveryoption,
          },
          restid as string
        )
        .then((response) => {
          toast.success(t('alerts.success.create'), { containerId: 'B' });
          props.toggle && props.toggle();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' });
        });
    } else {
      await API.restaurantApi
        .updateRestaurantWorkingHours(
          {
            restaurant: restid as string,
            weekday,
            to_hour,
            from_hour,
            pickup_option: pickoption,
            delivery_option: deliveryoption,
          },
          id
        )
        .then((response) => {
          toast.success(t('alerts.success.update'), { containerId: 'B' });
          props.toggle && props.toggle();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' });
          props.toggle && props.toggle();
        });
    }
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.workinghourid === 'new' ? t('dashboard.add_new_hour') : t('dashboard.edit_new_hour')}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="form-group text-left">
              <InputField name="id" type="hidden" value={Workinghour.id} />
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                <label htmlFor="" className="control-label font-weight-bold m-0 mb-2">
                  {t('dashboard.choose_weekday')}:
                  <select
                    className="form-control mt-2"
                    name="weekday"
                    value={Workinghour.weekday}
                    onChange={(e) => changeworkinghour(e)}
                  >
                    <option value="Monday">{t('weekdays.Monday')}</option>
                    <option value="Tuesday">{t('weekdays.Tuesday')}</option>
                    <option value="Wednesday">{t('weekdays.Wednesday')}</option>
                    <option value="Thursday">{t('weekdays.Thursday')}</option>
                    <option value="Friday">{t('weekdays.Friday')}</option>
                    <option value="Saturday">{t('weekdays.Saturday')}</option>
                    <option value="Sunday">{t('weekdays.Sunday')}</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.start')}
                </label>
                <InputField
                  required
                  name="from_hour"
                  placeholder="1:00:00"
                  value={Workinghour.from_hour}
                  onChange={(e: any) => changeworkinghour(e)}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {t('dashboard.end')}
                </label>
                <InputField
                  required
                  placeholder="9:00:00"
                  name="to_hour"
                  value={Workinghour.to_hour}
                  onChange={(e: any) => changeworkinghour(e)}
                />
              </div>
            </div>
            <div className="form-group col-sm-12">
              <label htmlFor="delivery" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {t('dashboard.delivery')}
              </label>
              <select
                value={Workinghour.delivery_option}
                onChange={(e) => changeworkinghour(e)}
                name="deliveryoption"
                className="form-control"
                id="delivery"
              >
                <option value="true">{t('dashboard.on')}</option>
                <option value="false">{t('dashboard.off')}</option>
              </select>
            </div>
            <div className="form-group col-sm-12">
              <label htmlFor="pickoption" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {t('dashboard.pickup')}
              </label>
              <select
                value={Workinghour.pickup_option}
                onChange={(e) => changeworkinghour(e)}
                name="pickupoption"
                className="form-control"
                id="pickoption"
              >
                <option value="true">{t('dashboard.on')}</option>
                <option value="false">{t('dashboard.off')}</option>
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            name={t('dashboard.cancel')}
            type="reset"
            btnLongWidth={false}
            onClick={refresh}
          />
          <Button
            name={t('dashboard.save')}
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default WorkingHoursModal;
