import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../services/urls';
import './style.css';
import Button from './Button';
import { API } from '../api';

const OrderModel: FC<any> = (props) => {
  const [Orderdata, setOrderdata] = useState<any>('');
  const [theOrder, setTheOrder] = useState<any>();
  const id = localStorage.getItem('rest_id') as string;
  const { t, i18n } = useTranslation();

  const getOrder = async () => {
    await API.restaurantApi
      .getOrder(id, props.orderId)
      .then((res) => {
        setTheOrder(res.data.orders[0]);

        setOrderdata(res.data.Articles);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    getOrder();
  }, [props]);

  const refresh = (e: any) => {
    e.preventDefault();
    props.setModal(!props.modal);
  };

  interface Ctg {
    id: number;
    ctg_name: string;
    articles: any[];
  }

  const getOrderCtgs = (orderData: any): Ctg[] => {
    // Get Unique Categories For Current Order
    let ctgs: Ctg[] = [];
    orderData.map((item: any) => {
      const currCtg: Ctg = {
        id: item.article.category.id,
        ctg_name: item.article.category.name,
        articles: [],
      };
      if (!ctgs.find((i) => i.id === currCtg.id)) {
        ctgs.push(currCtg);
      }
    });

    return ctgs;
  };

  const getOptionNameById = (id: string) => {
    if (!id) return;

    const art = theOrder?.article?.find((elem: any) => elem.article.id === id);
    const art_opt = art?.article_options[0]?.article_option;
    const name = art_opt?.choice;
    const price = art_opt?.choice_price;
    const alias = art_opt?.seletedalias?.alias;
    return [name, price, alias];
  };

  const getOptsCtgs = (opt_array: any[]) => {
    const res_arr: any[] = [];
    opt_array.forEach((item: any) => {
      const already_there = res_arr.find(
        (itm: any) => itm.id === item?.article_option?.optionname?.id
      );
      if (!already_there) {
        res_arr.push({
          name: item?.article_option?.optionname?.optionname,
          id: item?.article_option?.optionname?.id,
        });
      }
    });

    return res_arr;
  };

  const getOptsForCtg = (ctg: any, opts_array: any[]) => {
    const opts_for_ctg = opts_array.filter(
      (item: any) => item?.article_option?.optionname?.id === ctg.id
    );
    return opts_for_ctg;
  };

  const renderOptsForCtg = (opts: any[]) => {
    if (!opts) return null;
    return (
      <span>
        (
        {opts.map((item: any, index: any) => (
          <span>
            {item?.article_option?.choice}
            {index + 1 < opts.length ? ', ' : ''}
          </span>
        ))}
        )
      </span>
    );
  };

  const renderArtOptions = (opt_array: any[]) => {
    const opts_ctgs = getOptsCtgs(opt_array);

    return (
      <div className="col-12">
        {opts_ctgs.map((opt_ctg: any) => {
          return (
            <React.Fragment key={opt_ctg.id}>
              <span>{opt_ctg.name} </span>
              <span>{renderOptsForCtg(getOptsForCtg(opt_ctg, opt_array))}</span>
              <br />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const renderCtgs = (orderData: any) => {
    let ctgs = getOrderCtgs(orderData);

    return (
      <React.Fragment>
        <div className="row">
          <h1 className="bestelling-title">
            {t('orders.order')} #{theOrder?.order_number}
          </h1>
        </div>
        {ctgs.map((ctg) => {
          return (
            <React.Fragment key={ctg.id}>
              <div className="row">
                <div className="col-6">
                  <h3 className="bestelling-ctg-title">{ctg.ctg_name}</h3>
                </div>
              </div>
              {orderData.map((item: any) => {
                if (ctg.id === item.article.category.id) {
                  return (
                    <>
                      <div className="row" key={item.article.id}>
                        <span className="col-6">
                          {item.article_quantity} X {item.article.articlename}
                          <br />
                        </span>
                        <span className="col-6 text-right">€{item.article_total_prize}</span>
                      </div>
                      {item.article?.optionsname?.length > 0 && (
                        <div className="row ml-3">{renderArtOptions(item.article_options)}</div>
                      )}
                    </>
                  );
                }
              })}
            </React.Fragment>
          );
        })}
        <div className="row mt-5">
          <div className="col-6 font-weight-bold">{t('orders.service_cost')}</div>
          <div className="col-6 text-right font-weight-bold">€{theOrder?.service_cost}</div>
        </div>
        <div className="row">
          <div className="col-6 font-weight-bold">{t('orders.delivery_cost')}</div>
          <div className="col-6 text-right font-weight-bold">€{theOrder?.delivery_cost}</div>
        </div>
        <div className="row">
          <div className="col-6 font-weight-bold">{t('orders.total')}</div>
          <div className="col-6 text-right font-weight-bold">€{theOrder?.total_amount}</div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Modal className="modal-lg" isOpen={props.modal} toggle={(e: any) => props.toggle(e)}>
      {Orderdata && (
        <>
          <ModalBody>
            <div className="container">{renderCtgs(Orderdata)}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              name={t('orders.cancel')}
              dangerButton={true}
              btnLongWidth={false}
              onClick={(e: any) => refresh(e)}
            />
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
export default OrderModel;
