import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import moment from 'moment';

interface Props {
  printer: {
    activity_status: string;
    added: string;
    current_order: string | null;
    encodings: string | null;
    id: number | string | null;
    mac_address: string | null;
    poll_interval: number | string | null;
    restaurant: number | string | null;
    unique_id: string;
  };
  modal: boolean;
  printerId: string;
  toggle: () => {};
}

const PrinterModal: FC<any> = (props: Props) => {
  const restId = localStorage.getItem('rest_id') as string;

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  const { t, i18n } = useTranslation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Call to Printer Update API Here
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  const { printer } = props;

  return printer ? (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Printer #{printer.id}</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.id')}</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.id}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.unique_id')}</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.unique_id}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.status')}</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.activity_status}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.encodings')}</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.encodings}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.order_id')}</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.current_order || 'Not printing'}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.mac_address')}</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.mac_address}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.poll_interval')}</span>
            </div>
            <div className="col-9 text-right">
              <span>
                {printer.poll_interval} {t('printers.seconds')}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>{t('printers.created_at')}</span>
            </div>
            <div className="col-9 text-right">
              <span>{moment(printer.added).format('YYYY/MM/DD HH:mm')}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-danger" onClick={handleCancel} type="button">
            {t('printers.cancel')}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  ) : null;
};
export default PrinterModal;
